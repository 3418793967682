// Formattor for Random Gift Picker
// Turning off key spacing due to large dataset formatting
/* Eslint key-spacing: 0 */
/* Eslint complexity: 0 */

var randomPrize = window.randomPrize || {};
var generic = window.generic || {};

randomPrize.GameReady = $.Deferred();
randomPrize.offerApplied = $.Deferred();
randomPrize.products = [];
randomPrize.offer_array = [];
randomPrize.number_of_prizes = '';
randomPrize.winning_offer;
randomPrize.winning_sku;
randomPrize.winning_product;
randomPrize.winning_category;
randomPrize.offer_probability = [];
randomPrize.offerProducts = {};
randomPrize.available_offers = {};
randomPrize.continuePlayOnceWon = 1;
randomPrize.stopGameFor24HoursOnceWon = 1;
randomPrize.skuFilter = ['INVENTORY_STATUS', 'SKU_ID', 'PRODUCT_CODE'];
randomPrize.productFilter = ['LARGE_IMAGE'];
randomPrize.categoryFilter = [];
randomPrize.offerFilter = [];
randomPrize.prizeData = {};
randomPrize.settings = {};
randomPrize.getPrizeOnPageLoad = true;
randomPrize.overrideSuccessContent = false;

/**
 * Convenience method to run all methods in this object
 * @param {array} prodArray - array of products
 * @intended access public
 *
 * @returns {bool} true
 */
randomPrize.init = function () {
  var successPopupContentCMS;
  randomPrize.getDom();
  randomPrize.showLoading();
  randomPrize.parseOfferAndProbability();
  $.when(randomPrize.GameReady).then(function () {
    successPopupContentCMS =
        $.trim(randomPrize.nodes.successOverlayCopy.html());

    if (!successPopupContentCMS.length) {
      randomPrize.overrideSuccessContent = true;
    }
    randomPrize.setUpGame();
  });

  return true;
};

/**
 * Set up Game
 *
 * @returns {boolean} false - if game is not valid to play now
 * @returns {boolean} true - if game is valid to play now 
 */
randomPrize.setUpGame = function () {
  if (randomPrize.isGameValidforPlay()) {
    randomPrize.getPlay();

    return 1;
  } else {
    return 0;
  }
};

/**
 * Validate Game
 *
 * @returns {boolean} false - if any/all required conditions fail
 * @returns {boolean} true - if all required conditions pass
 */
randomPrize.isGameValidforPlay = function () {
  if (randomPrize.getNumberOfTriesCookie() 
      <= randomPrize.settings.numberOfTries) {
    if (randomPrize.continuePlayOnceWon || !randomPrize.has_won) {
      if (!randomPrize.stopGameFor24HoursOnceWon || !randomPrize.get24HourCookie()) {
        return 1;
      }
    }
  }

  return 0;
};

/**
 * Override game enable time
 *
 * @returns {number} futureHours - The time at which the game can be played again. If 7, means 7 AM
 */
randomPrize.overrideGameEnableTime = function () {
  var isGameTimeToBeOverridden = 
      parseInt(randomPrize.settings.overrideTimeLimit, 10);
  var timeInHours = parseInt(randomPrize.settings.gameEnableTime, 10);
  var date;
  var pastOrFutureTime;
  var currentHour;
  var currentMinutes;
  var futureHours;

  if (isGameTimeToBeOverridden && timeInHours) {
    date = new Date();
    currentHour = date.getHours();
    currentMinutes = date.getMinutes();
    pastOrFutureTime = timeInHours - currentHour;
    if (pastOrFutureTime >= 0) {
      futureHours = 
          (timeInHours * 60) - ((currentHour * 60) + currentMinutes);
    } else {
      futureHours = 
          (24 * 60) - ((currentHour * 60) + currentMinutes) + (timeInHours * 60);
    }

    return futureHours / 60;
  }

  return 0;
};

/**
 * Set 24 hour cookie once won
 *
 * @param {boolean} won - If value is 1, set cookie 
 * @returns {boolean} false
 */
randomPrize.set24HourCookie = function (wonOffer) {
  if (wonOffer) {
    var date = new Date();
    var hours = randomPrize.overrideGameEnableTime() || 24;

    date.setTime(date.getTime() + (hours * 60 * 60 + 1) * 1000);
    document.cookie = 'game_twenty_four=1;expires=' + date.toUTCString() + ';';
  }
};

/**
 * Get 24 hour cookie
 * 
 * @returns {boolean} false - 
 * if cookie 'game_twenty_four' is not available
 * @returns {boolean} true - 
 * if cookie 'game_twenty_four' is available
 */
randomPrize.get24HourCookie = function () {
  if (randomPrize.getCookie('game_twenty_four')) {
    return 1;
  }

  return 0;
};

/**
 * Get number of tries cookie
 *
 * @returns {boolean} false
 */
randomPrize.getNumberOfTriesCookie = function () {
  if (randomPrize.getCookie('game_tries')) {
    return randomPrize.getCookie('game_tries');
  }

  return 0;
};

/**
 * Set number of tries cookie
 *
 * @param {boolean} expiresIn24Hrs - 
 * If value is 1, set cookie to expire in 24hrs
 * @returns {boolean} false
 */
randomPrize.setNumberOfTriesCookie = function (expiresIn24Hrs) {
  var date;
  var expires = '';
  var cookieCalc;
  var hours;

  if (expiresIn24Hrs) {
    date = new Date();
    hours = randomPrize.overrideGameEnableTime() || 24;
    date.setTime(date.getTime() + (hours * 60 * 60 + 1) * 1000);
    expires = ';expires=' + date.toUTCString() + ';';
  }
  if (!randomPrize.getNumberOfTriesCookie()) {
    document.cookie = 'game_tries = 1' + expires;
  } else {
    cookieCalc = parseInt(randomPrize.getNumberOfTriesCookie(), 10) + 1;
    document.cookie = 'game_tries = ' + cookieCalc + expires;
  }
};

/**
 * Set winning offer code cookie, available 24h
 *
 * @param {boolean} offer_code - winning offer code
 * @returns {boolean} false
 */
randomPrize.setWinningOfferCookie = function (offer_code) {
  var date = new Date();
  var hours = randomPrize.overrideGameEnableTime() || 24;

  date.setTime(date.getTime() + (hours * 60 * 60 + 1) * 1000);
  document.cookie = 'winning_offer=' + offer_code + ';expires=' + date.toUTCString() + ';';
};

/**
 * Get winning offer cookie
 *
 * @returns {string} - Offer code if available otherwise empty string
 */
randomPrize.getWinningOfferCookie = function () {
  var winning_offer = randomPrize.getCookie('winning_offer');

  return winning_offer ? winning_offer : '';
};

/**
 * Get any cookie
 *
 * @param {string} name - Cookie name
 * @returns {string} - Cookie value
 */
randomPrize.getCookie = function (name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
};

/**
 * Set cookie
 *
 * @param {string} name - Cookie name
 * @param {string} value - Cookie value
 * @param {number} expiresInHrs - Expires hours in number
 * @returns {boolean} false
 */
randomPrize.setCookie = function (name, value, expiresInHrs) {
  var expires = '';
  var hours = parseFloat(expiresInHrs);
  var date;

  if (hours > 0) {
    date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 + 1) * 1000);
    expires = ';expires=' + date.toUTCString() + ';';
  }
  if (name && name.length) {
    document.cookie = name + '= ' + value + expires;
  }
};

/**
 * RandomPrize.getDom
 * This is where we get all the DOM object
 *
 * @returns {boolean} false
 */
randomPrize.getDom = function () {
  var $formatter = $('.js-random-gift-picker-formatter-v1');

  randomPrize.nodes = randomPrize.nodes || {};
  randomPrize.nodes = {
    landing: $('.js-game-landing', $formatter),
    unavailable: $('.js-game-unavailable', $formatter),
    loading: $('.js-game-loading', $formatter),
    applyOfferBtn: $('.js-apply-offer', $formatter),
    successOverlayContainer: $('.js-random-gift-picker-success-overlay'),
    inactiveOverlayContainer: $('.js-random-gift-picker-inactive-overlay'),
    inactiveOverlayContent: $('.js-random-gift-picker-inactive-overlay-content'),
    successOverlayCopy: $('.js-random-gift-picker-success-copy'),
    errors: {
      noSearchTerm: $('ul.error_messages li.no-search-term')
    }
  };
  randomPrize.settings = {
    overrideTimeLimit: $formatter.attr('data-override-24hour-time-limit') || 0,
    gameEnableTime: $formatter.attr('data-game-enable-time') || 0,
    showGameInactiveOverlay: $formatter.attr('data-show-game-inactive-overlay') || 0,
    inactiveOverlayBgColor: $formatter.attr('data-game-inactive-overlay-bg-color') || 0,
    inactiveOverlayBgImage: $formatter.attr('data-game-inactive-overlay-bg-transparency-image') || 0,
    successOverlayBgColor: $formatter.attr('data-success-overlay-bg-color') || 0,
    showSuccessOverlay: $formatter.attr('data-show-success-overlay') || 0,
    usingCustomImages: $formatter.attr('data-popup-show-custom-images') || 0,
    customImageFolder: $formatter.attr('data-popup-custom-image-folder') || '',
    offerCodes: $formatter.attr('data-offer-codes') || '',
    numberOfTries: parseInt($formatter.attr('data-no-of-tries'), 10) || 3,
    winChance: parseInt($formatter.attr('data-win-chance'), 10) || 50,
    inactiveOverlayWidth: $formatter.attr('data-game-inactive-overlay-width') || '490px',
    inactiveOverlayHeight: $formatter.attr('data-game-inactive-overlay-height') || '345px',
    successOverlayWidth: $formatter.attr('data-success-overlay-width') || '490px',
    successOverlayHeight: $formatter.attr('data-success-overlay-height') || '345px',
    hideLoadingPopup: parseInt($formatter.attr('data-loading-message-display-time'), 10) || 0
  };
};

/**
 * RandomPrize.checkProbability
 * Check the probability of the offers
 *
 * @param {object} offers - 
 * An array of offers, that should have a probability property
 * @param {number} totalProbability - The total probabilty of all the items
 * @returns {object} - the result of the offers
 */
randomPrize.checkProbability = function (offers, totalProbability) {
  var returnObj = {
    offers: [],
    probabilities: {}
  };
  if (!offers) {
    return;
  }
  // Set the default to 100
  totalProbability = totalProbability || 100;
  $.each(offers, function (key, value) {
    var offerAndProbability = value.split ? value.split(':') : value;
    var availableOfferObject = randomPrize.available_offers[value];
    var probability;

    // Check if the offer is available and it has the probability property
    probability = typeof availableOfferObject ===
        'object' ? availableOfferObject.probability : 0 || (100 / Object.keys(offers).length);
    // Returns Object as it's an [Array] Object from a string split
    if (typeof offerAndProbability === 'object') {
      // Checks that there are items in the object
      if (Object.keys(offerAndProbability).length > 1) {
        // The offer works
        returnObj.offers[key] = offerAndProbability[0];
        returnObj.probabilities[offerAndProbability[0]] =
            offerAndProbability[1];
      } else {
        // It's a single offer
        if (availableOfferObject) {
          returnObj.offers[key] = offerAndProbability[0];
          returnObj.probabilities[offerAndProbability[0]] =
              (probability / totalProbability) * 100;
        }
      }
    } else {
      // Should never hit here as offers should always be an object [array]
      returnObj.offers[key] = key;
      // Add the probability default as a backup
      returnObj.probabilities[offerAndProbability[0]] =
          (probability / totalProbability) * 100;
    }
  });

  return returnObj;
};

/**
 * RandomPrize.checkTotalProbability
 * Calculate the probability that each item will have
 *
 * @param {array} offers - the list of offers you want the probability from
 * @returns {number} total probility
 */
randomPrize.checkTotalProbability = function (offers) {
  var totalProbability = 0;

  $.each(offers, function (key, value) {
    if (value === null) {
      delete offers[key];
    } else {
      // Check all offers are active
      if (value.timing !== 'active') {
        delete offers[key];
      } else {
        value.probability = value.probability || 100 / Object.keys(offers).length;
        totalProbability += value.probability;
      }
    }
  });

  return totalProbability;
};

/**
 * RandomPrize.parseOfferAndProbability
 * This is where we get all the offers,
 *  Set the probability (if set in Drupal) and set the winning price
 * @returns {boolean} false
 */
randomPrize.parseOfferAndProbability = function () {
  randomPrize.offer_array = $.map(randomPrize.settings.offerCodes.split(','), $.trim);
  var params = [
    {
      'offers': randomPrize.offer_array,
      'get_products': 1,
      'get_transaction': 0
    }
  ];

  generic.jsonrpc.fetch({
    method: 'offers.getOffersRpcData',
    params: params,
    onSuccess: function (response) {
      var responseValues = response.getValue();
      var offersProductsObj;
      var offersSkusObj;
      var offersProducts;
      var offerSkus;
      var prodId;

      if (!responseValues || responseValues.offers
          && !Object.keys(responseValues.offers).length) {
        randomPrize.getUnavailableMessage();

        return;
      }

      if (responseValues && responseValues.offers
          && Object.keys(responseValues.offers).length) {
        randomPrize.available_offers = responseValues.offers;
      }

      if (responseValues && responseValues.offersProducts
          && Object.keys(responseValues.offersProducts).length) {
        offersProductsObj = responseValues.offersProducts;
        offersSkusObj = offersProductsObj.skus;
        offersProducts = offersProductsObj.products;
        randomPrize.offerProducts = offersProductsObj;
        if (offersSkusObj && Object.keys(offersSkusObj).length) {
          for (var offerCodes in offersSkusObj) {
            offerSkus = offersSkusObj[offerCodes];
            randomPrize.offerProducts[offerCodes] = {};
            randomPrize.offerProducts[offerCodes].skus = offerSkus;
            if (offersProducts && Object.keys(offersProducts).length) {
              randomPrize.offerProducts[offerCodes].products = [];
              for (var i = 0, j = offerSkus.length; i < j; i++) {
                prodId = offerSkus[i].PRODUCT_ID;

                randomPrize.offerProducts[offerCodes].products.push(offersProducts[prodId]);
              }
            }
          }
        }
      }

      $.each(randomPrize.available_offers, function (key, value) {
        if (value === null) {
          delete randomPrize.available_offers[key];
        } else {
          // Check all offers are active
          if (value['timing'] !== 'active') {
            delete randomPrize.available_offers[key];
          }
        }
      });

      if (Object.keys(randomPrize.available_offers).length > 0) {
        // Grab the probability that each item in the array will show
        var probabilty = randomPrize.checkProbability(randomPrize.offer_array,
            randomPrize.checkTotalProbability(randomPrize.available_offers));

        // Assign the the globals
        randomPrize.offer_array = probabilty.offers;
        randomPrize.offer_probability = probabilty.probabilities;
        if (randomPrize.getPrizeOnPageLoad) {
          randomPrize.setPrize(randomPrize.offer_array, randomPrize.offer_probability);
          randomPrize.getPrize();
        }
        // Now we can do some animation stuff, play a game etc
        randomPrize.GameReady.resolve();
      } else {
        randomPrize.getUnavailableMessage();
      }
    },
    onFailure: function () {
      randomPrize.getUnavailableMessage();
    }
  });
};

/**
 * Random.setPrize
 * This is where we set the prize before we even start playing the game
 *
 * @param {array} offers - Offers array
 * @param {object} probability - Offers probability object
 * @returns {boolean} false
 */
randomPrize.setPrize = function (offers, probability) {
  var randomNum = randomPrize.getRandomNumber(1, 100);
  var weightSum = 0;
  var winningOfferProduct;
  var winningOfferObj;
  var inventoryStatus;

  for (var i = 0; i < offers.length; i++) {
    weightSum += parseInt(probability[offers[i]], 10) || 0;
    weightSum = +weightSum;
    if (randomNum <= weightSum) {
      winningOfferObj = randomPrize.available_offers[offers[i]];
      if (typeof winningOfferObj === 'object') {
        randomPrize.winning_offer = winningOfferObj;
        winningOfferProduct = 
            randomPrize.offerProducts[randomPrize.winning_offer.offer_code];
        /** Once we have the offer we can now get the prize 
         * If it is a sku related product
         */
        if (typeof winningOfferProduct === 'object') {
          /** If a product type offer we should always 
           * Have a sku as the backend should always return this 
           */
          if (winningOfferProduct.skus && winningOfferProduct.skus.length) {
            randomPrize.winning_sku =
                winningOfferProduct.skus[randomPrize.getRandomNumber(0, winningOfferProduct.skus.length - 1)];
            // If the sku is active/promotional then proceed
            inventoryStatus = randomPrize.checkInventoryStatus();
            if (inventoryStatus === 1 || inventoryStatus === 6) {
              // Set the products
              randomPrize.winning_product = winningOfferProduct.products[0];
            } else {
              // If the sku is oos/inactive then choose another offer
              randomPrize.setPrize(randomPrize.offer_array, randomPrize.offer_probability);
            }
          }
        }
      } else {
        // If the offer is invalid then choose another offer
        randomPrize.setPrize(randomPrize.offer_array, randomPrize.offer_probability);
      }
      break;
    }
  }
};

/**
 * Random.getPrize
 * This is where we get the prize before we even start playing the game
 * @returns {object} prizeData
 *
 */
randomPrize.getPrize = function () {
  // Do we have a sku as a prize?
  if (typeof randomPrize.winning_sku === 'object') {
    $.each(randomPrize.skuFilter, function (key, value) {
      randomPrize.prizeData[value] =
          randomPrize.winning_sku &&
          randomPrize.winning_sku[value] ? randomPrize.winning_sku[value] : '';
    });
    $.each(randomPrize.productFilter, function (key, value) {
      randomPrize.prizeData[value] =
          randomPrize.winning_product &&
          randomPrize.winning_product[value] ? randomPrize.winning_product[value] : '';
    });
    $.each(randomPrize.categoryFilter, function (key, value) {
      randomPrize.prizeData[value] =
          randomPrize.winning_category &&
          randomPrize.winning_category[value] ? randomPrize.winning_category[value] : '';
    });
    $.each(randomPrize.offerFilter, function (key, value) {
      randomPrize.prizeData[value] =
          randomPrize.winning_offer &&
          randomPrize.winning_offer[value] ? randomPrize.winning_offer[value] : '';
    });
  }

  return randomPrize.prizeData;
};

/**
 * RandomPrice.checkInventoryStatus
 * This is where we get the prize before we even start playing the game
 *
 * @returns {number} INVENTORY_STATUS
 */
randomPrize.checkInventoryStatus = function () {
  return randomPrize.winning_sku['INVENTORY_STATUS'];
};

/**
 * RandomPrice.isShoppable
 * This is where we get the prize before we even start playing the game
 *
 * @returns {boolean} isShoppable
 */
randomPrize.isShoppable = function () {
  return randomPrize.winning_sku.isShoppable;
};

/**
 * Calculate if the user has won the offer yet
 *
 * @returns {boolean} false
 */
randomPrize.winOrNoWin = function () {
  if (Math.round(Math.random() * 100) < randomPrize.settings.winChance) {
    return true;
  }

  return false;
};

/**
 * RandomPrice.getUnavailableMessage
 * Show the unavailable message
 *
 * @returns {boolean} false
 */
randomPrize.getUnavailableMessage = function () {
  randomPrize.hideLoading();
  randomPrize.nodes.unavailable.removeClass('hidden');
  randomPrize.nodes.landing.hide();
  if ($(window).width() < 990) {
    $('html, body').animate({ scrollTop: 0 }, 1000);
  }
};

/**
 * RandomPrice.showLoading
 * show the loading page whilst RPC call gets data
 *
 * @returns {boolean} false
 */
randomPrize.showLoading = function () {
  randomPrize.nodes.loading.removeClass('hidden');
};

/**
 * RandomPrice.hideLoading
 * Hide the loading page when RPC call gets complete
 *
 * @returns {boolean} false
 */
randomPrize.hideLoading = function () {
  setTimeout(function () {
    randomPrize.nodes.loading.addClass('hidden');
  }, randomPrize.settings.hideLoadingPopup * 1000);
};

/**
 * RandomPrice.getPlay
 * setUp the game and bind events
 *
 * @returns {boolean} false
 */
randomPrize.getPlay = function () {
  randomPrize.hideLoading();

  // Click to apply the offer
  randomPrize.nodes.applyOfferBtn.on('click', function (e) {
    e.preventDefault();
    $(this).addClass('loader');
    randomPrize.applyOffer();
  });
};

/**
 * Function for applying the offer
 *
 * @returns {boolean} false
 */
randomPrize.applyOffer = function () {
  var winningOfferInfo = randomPrize.getWinningOffer();

  if (!winningOfferInfo.valid) {
    return;
  }
  generic.jsonrpc.fetch({
    method: 'offers.apply',
    params: [
      {
        'offer_code': winningOfferInfo.winning_offer.offer_code
      }
    ],
    onBoth: function () {
      randomPrize.offerApplied.resolve();
    }
  });
};

/**
 * Function to show success overlay when offer is added
 * to cart
 *
 * @returns {boolean} false
 */
randomPrize.successOverlay = function () {
  var successbgColor = randomPrize.settings.successOverlayBgColor;
  var $overlaySuccess;

  if (generic.overlay) {
    generic.overlay.launch({
      content: randomPrize.nodes.successOverlayContainer.html(),
      includeBackground: false,
      lockPosition: false,
      overlayClose: false,
      closeButton: false,
      escKey: false,
      cssClass: 'random-gift-picker__overlay-container-success-overlay js-game-success-overlay-bg',
      cssStyle: {
        width: randomPrize.settings.successOverlayWidth,
        height: randomPrize.settings.successOverlayHeight
      }
    });
    $overlaySuccess = $('#cboxOverlay.js-game-success-overlay-bg');
    if (successbgColor && successbgColor.length) {
      $overlaySuccess.css('background-color', successbgColor);
    }
  }
};

/**
 * Function to show game inactive message in overlay
 * The message says that the game can be played after
 * certain hours
 *
 * @returns {boolean} false
 */
randomPrize.gameInactiveOverlay = function () {
  var bgColor = randomPrize.settings.inactiveOverlayBgColor;
  var bgImage = randomPrize.settings.inactiveOverlayBgImage;
  var $overrideTransparency =
      randomPrize.nodes.inactiveOverlayContainer.filter('.js-inactive-overlay-override-transparency');
  var $overlayBlock;
  var $closeBtn;
  var bgImageUrl;

  if (!randomPrize.settings.showGameInactiveOverlay) {
    return false;
  }
  if (generic.overlay) {
    generic.overlay.launch({
      content: randomPrize.nodes.inactiveOverlayContainer.html(),
      includeBackground: false,
      lockPosition: false,
      overlayClose: false,
      closeButton: false,
      escKey: false,
      cssClass: 'random-gift-picker__overlay-container-inactive-overlay js-game-inactive-overlay-bg',
      cssStyle: {
        width: randomPrize.settings.inactiveOverlayWidth,
        height: randomPrize.settings.inactiveOverlayHeight
      }
    });
    $overlayBlock = $('#cboxOverlay.js-game-inactive-overlay-bg');
    if (bgColor && bgColor.length) {
      $overlayBlock.css('background-color', bgColor);
    }
    if (bgImage && bgImage.length) {
      bgImageUrl = "url('" + bgImage + "')";

      $overlayBlock.css({
        'background-image': bgImageUrl,
        'background-size': '100% 100%'
      });
    }
    if ($overrideTransparency.length) {
      $overlayBlock.css('opacity', $overrideTransparency.attr('data-transparency'));
    }
    randomPrize.nodes.inactiveOverlayContent = $('.js-random-gift-picker-inactive-overlay-content');
    if (randomPrize.nodes.inactiveOverlayContent.children('#cboxClose').length === 0) {
      $closeBtn = $('#cboxClose').clone();
      $closeBtn.once().on('click', function () {
        generic.overlay.hide();
      });
      randomPrize.nodes.inactiveOverlayContent.append($closeBtn);
    }
  }
};

/**
 * Function for returning a random number
 *
 * @param {number} numberStart - starting range
 * @param {number} numberRange - ending range
 * @returns {number} - random number
 */
randomPrize.getRandomNumber = function (numberStart, numberRange) {
  return Math.floor((Math.random() * numberRange) + numberStart);
};

/**
 * Returns a JSON Object with the Winning Information
 *
 * @returns {Object} winInfo - Winning information in a Object Form
 */
randomPrize.getWinningOffer = function () {
  // Check the offer we are showing, what is it?
  var offerCode = 'winning_offer' in randomPrize ? randomPrize.winning_offer.offer_code : '';
  var offerCodeCookie = randomPrize.getWinningOfferCookie();
  var offer;
  var winningProduct;
  var winningConfirmMessage;
  // Is this a SKU Offer
  var isSkuOffer = false;
  var skus = [];
  var prodID;

  if (offerCodeCookie && offerCodeCookie !== 'none') {
    offerCode = offerCodeCookie;
  }
  offer = offerCode in randomPrize.available_offers ?
    randomPrize.available_offers[offerCode] : false;

  // There is no offer to return to the user
  if (!offer) {
    return {
      valid: false,
      error: 'no_offer'
    };
  }

  for (var i = 0; i < offer.benefits.length; i++) {
    if (offer.benefits[i].class === 'Skus') {
      isSkuOffer = true;
      for (var j = 0; j < offer.benefits[i].skus; j++) {
        skus.push(offer.benefits[i].skus[j]);
      }
      break;
    }
  }

  /**
  * Does the offer have a SKU?
  * If it does lets grab a product
  * Pick the first match for the product
  */

  winningProduct = false;

  if (isSkuOffer && randomPrize.settings.usingCustomImages === '0') {
    // Grab the default product used by the offer
    prodID = randomPrize.offerProducts.skus[offerCode][0].PRODUCT_ID;

    winningProduct = randomPrize.offerProducts.products[prodID];
  }

  winningConfirmMessage =
      String(offer.confirm_message).replace('::skuname::', winningProduct ? winningProduct.PROD_RGN_NAME : '');

  // Return the Offer Object
  return {
    // Is the offer Valid or not
    valid: true,
    // Add the winning offer object
    winning_offer: offer,
    // Winning title,
    title: '',
    // Description for the win message
    description: offer.description,
    // Confirm message
    confirmMessage: winningConfirmMessage,
    // The image assosiated with this winning offer
    image: winningProduct ? winningProduct.LARGE_IMAGE : randomPrize.settings.customImageFolder + offer.image,
    // Does the user have the ability to play again
    playAgain: parseInt(randomPrize.getNumberOfTriesCookie(), 10)
        < randomPrize.settings.numberOfTries
    //
  };
};
